import React from 'react';
import './style.scss';
var GenerateCard = function (_a) {
    var value = _a.value, delay = _a.delay;
    return (React.createElement("div", { className: "generate-card" },
        React.createElement("div", { className: "generate-card__content-wrapper" },
            React.createElement("span", { className: "generate-card__content" }, value)),
        React.createElement("div", { className: "generate-card__loading-bar" },
            React.createElement("div", { style: {
                    animationDelay: "".concat(delay, "s")
                } }))));
};
export default GenerateCard;
