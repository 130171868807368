import { NavLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Button from '../customUI/Button';
import { ROUTES } from '../../constants/routes';
import LogoPNG from '../../../assets/arrival-logo.png';
import './style.scss';

export default function Header() {
  const [, , removeCookie] = useCookies(['token']);

  const signOut = () => {
    removeCookie('token');
  };

  return (
    <nav className="header">
      <div>
        <Button onClick={() => signOut()}>Sign out</Button>
      </div>
      <div>
        <img src={LogoPNG} alt="arrival logo" />
      </div>
      <div className="header__link-wrapper">
        <NavLink
          to={ROUTES.userProfile}
          className={({ isActive }) => {
            return `header__link ${isActive ? 'header__link--active' : ''}`;
          }}
        >
          User profile
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return `header__link ${isActive ? 'header__link--active' : ''}`;
          }}
          to={ROUTES.editor}
        >
          Editor
        </NavLink>
      </div>
    </nav>
  );
}
