import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useDisconnect, useSwitchChain } from 'wagmi';
import { useEffect } from 'react';
import './style.scss';
import React from 'react';
export default function ConnectButton() {
    var open = useWeb3Modal().open;
    var _a = useAccount(), isConnected = _a.isConnected, chain = _a.chain;
    var disconnect = useDisconnect().disconnect;
    var switchChain = useSwitchChain().switchChain;
    useEffect(function () {
        if (isConnected) {
            switchChain({ chainId: 1 });
        }
    }, [chain, isConnected, switchChain]);
    return (React.createElement("button", { className: "connect-wallet-btn", onClick: function () { return (isConnected ? disconnect() : open()); } },
        isConnected ? 'Disconnect' : 'Connect',
        " wallet"));
}
