// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components {
  background-color: #242424;
  color: #c3c3c3;
  height: 100%;
  overflow: auto;
  position: fixed;
  width: 331px;
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/components/UI/componentsContainer/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;AACF","sourcesContent":[".components {\n  background-color: #242424;\n  color: #c3c3c3;\n  height: 100%;\n  overflow: auto;\n  position: fixed;\n  width: 331px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
