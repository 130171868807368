// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  background-color: #92374d;
  border: none;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
}
.btn:hover {
  background-color: #7e374d;
}`, "",{"version":3,"sources":["webpack://./src/components/customUI/Button/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,yBAAA;AACJ","sourcesContent":[".btn {\n  background-color: #92374d;\n  border: none;\n  color: white;\n  padding: 3px;\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 18px;\n  cursor: pointer;\n  border-radius: 4px;\n\n  &:hover {\n    background-color: #7e374d;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
