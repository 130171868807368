// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "BlinkMacSystemFont", "-apple-system", "Segoe UI", Helvetica, Arial, sans-serif;
}

.main {
  height: 100%;
  position: relative;
}

.main .main-wrapper {
  height: 100%;
}

.main a-scene {
  position: absolute;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA;;EAEE,YAAA;AACF;;AAEA;EACE,SAAA;EACA,UAAA;EACA,4FAAA;AACF;;AAGA;EACE,YAAA;EACA,kBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,MAAA;AAAF","sourcesContent":["html,\nbody {\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'BlinkMacSystemFont', '-apple-system', 'Segoe UI', Helvetica,\n    Arial, sans-serif;\n}\n\n.main {\n  height: 100%;\n  position: relative;\n}\n\n.main .main-wrapper {\n  height: 100%;\n}\n\n.main a-scene {\n  position: absolute;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
