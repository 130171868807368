import React from 'react';
import PropTypes from 'prop-types';
import { InputWidget } from '../../widgets';
import DEFAULT_COMPONENTS from '../DefaultComponents';
import PropertyRow from '../PropertyRow';
import Collapsible from '../../Collapsible';
import Mixins from '../Mixins';
import {
  updateEntity,
  getEntityClipboardRepresentation,
  printEntity
} from '../../../lib/entity';
import Events from '../../../lib/Events';
import Clipboard from 'clipboard';
import { saveBlob } from '../../../lib/utils';
import GLTFIcon from '../../../../../../assets/gltf.svg';
import './style.scss';

// @todo Take this out and use updateEntity?
function changeId(componentName, value) {
  var entity = AFRAME.INSPECTOR.selectedEntity;
  if (entity.id !== value) {
    entity.id = value;
    Events.emit('entityidchange', entity);
  }
}

export default class CommonComponents extends React.Component {
  static propTypes = {
    entity: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      slider: 1,
      isSliderChecked: false
    };
  }

  componentDidMount() {
    Events.on('entityupdate', (detail) => {
      if (detail.entity !== this.props.entity) {
        return;
      }
      if (DEFAULT_COMPONENTS.indexOf(detail.component) !== -1) {
        this.forceUpdate();
      }
    });

    Events.on('refreshsidebarobject3d', () => {
      this.forceUpdate();
    });

    var clipboard = new Clipboard('[data-action="copy-entity-to-clipboard"]', {
      text: (trigger) => {
        return getEntityClipboardRepresentation(this.props.entity);
      }
    });
    clipboard.on('error', (e) => {
      // @todo Show the error on the UI
    });
  }

  renderCommonAttributes() {
    const entity = this.props.entity;
    return ['position', 'rotation', 'scale', 'visible'].map((componentName) => {
      const schema = AFRAME.components[componentName].schema;
      var data = entity.object3D[componentName];
      if (componentName === 'rotation') {
        data = {
          x: THREE.MathUtils.radToDeg(entity.object3D.rotation.x),
          y: THREE.MathUtils.radToDeg(entity.object3D.rotation.y),
          z: THREE.MathUtils.radToDeg(entity.object3D.rotation.z)
        };
      }
      return (
        <PropertyRow
          onChange={updateEntity}
          key={componentName}
          name={componentName}
          showHelp={true}
          schema={schema}
          data={data}
          isSingle={true}
          componentname={componentName}
          entity={entity}
          slider={this.state.slider}
          setSlider={(value) => this.setState({ slider: value })}
          isSliderChecked={this.state.isSliderChecked}
          setSliderChecked={(value) =>
            this.setState({ isSliderChecked: value })
          }
        />
      );
    });
  }

  exportToGLTF() {
    const entity = this.props.entity;
    AFRAME.INSPECTOR.exporters.gltf.parse(
      entity.object3D,
      function (buffer) {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveBlob(blob, (entity.id || 'entity') + '.glb');
      },
      function (error) {
        console.error(error);
      },
      { binary: true }
    );
  }

  render() {
    const entity = this.props.entity;
    if (!entity) {
      return <div />;
    }
    const entityButtons = (
      <div>
        <a
          title="Export entity to GLTF"
          className="gltf-icon-sidebar"
          onClick={(event) => {
            this.exportToGLTF();
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <img src={GLTFIcon} />
        </a>
        <a
          title="Copy entity HTML to clipboard"
          data-action="copy-entity-to-clipboard"
          className="button fa fa-clipboard"
        />
      </div>
    );

    return (
      <Collapsible className="component-entity-header">
        <div className="component-entity-header__title">
          {printEntity(entity)}
          {entityButtons}
        </div>
        <div className="component-entity-header__collapsible-content">
          <div className="propertyRow">
            <label htmlFor="id" className="text">
              ID
            </label>
            <InputWidget
              onChange={changeId}
              entity={entity}
              name="id"
              value={entity.id}
            />
          </div>
          <div className="propertyRow">
            <label className="text">class</label>
            <span>{entity.getAttribute('class')}</span>
          </div>
          {this.renderCommonAttributes()}
          <Mixins entity={entity} />
        </div>
      </Collapsible>
    );
  }
}
