import React from 'react';
import { useAuth } from '../../context/authProvider';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
var ProtectedRoute = function (_a) {
    var children = _a.children;
    var token = useAuth().token;
    if (!token) {
        return React.createElement(Navigate, { to: ROUTES.login, replace: true });
    }
    return children;
};
export default ProtectedRoute;
