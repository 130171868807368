// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  height: 100%;
  justify-content: space-between;
}
.generate__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 10px;
  border-bottom: 1px solid #333333;
}
.generate__prompt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.generate__textarea {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  resize: none;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #333333;
}
.generate__button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  margin-bottom: 10px;
}
.generate__button--disabled {
  background-color: #a9a9a9;
  color: gray;
  cursor: not-allowed;
}
.generate__button--enabled {
  background-color: #92374d;
  color: white;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/style/generate.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gCAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,qBAAA;EACA,yBAAA;AADJ;AAIE;EACE,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAFJ;AAII;EACE,yBAAA;EACA,WAAA;EACA,mBAAA;AAFN;AAKI;EACE,yBAAA;EACA,YAAA;EACA,eAAA;AAHN","sourcesContent":[".generate {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  overflow-x: hidden;\n  height: 100%;\n  justify-content: space-between;\n\n  &__list {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n    margin-top: 10px;\n    border-bottom: 1px solid #333333;\n  }\n\n  &__prompt-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  &__textarea {\n    width: 100%;\n    height: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    padding: 10px;\n    resize: none;\n    border-radius: 5px;\n    overflow-x: hidden;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n    word-wrap: break-word;\n    background-color: #333333;\n  }\n\n  &__button {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    border-radius: 5px;\n    border: none;\n    width: 100%;\n    margin-bottom: 10px;\n\n    &--disabled {\n      background-color: #a9a9a9;\n      color: gray;\n      cursor: not-allowed;\n    }\n\n    &--enabled {\n      background-color: #92374d;\n      color: white;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
