var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import Api from '../../utils/api';
import { useForm } from 'react-hook-form';
import { ARRIVALS_API_URL } from '../../config';
import './style.scss';
var VIEWS = {
    LOGIN: 'login',
    REGISTER: 'register'
};
var SignIn = function () {
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, setError = _a.setError, setValue = _a.setValue, errors = _a.formState.errors, clearErrors = _a.clearErrors;
    var _b = useState('login'), activeView = _b[0], setActiveView = _b[1];
    var _c = useState(false), successMessage = _c[0], setSuccessMessage = _c[1];
    useEffect(function () {
        setValue('email', '');
        setValue('password', '');
        clearErrors();
    }, [activeView]);
    var handleLogin = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var err_1;
        var email = _b.email, password = _b.password;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Api.login({ email: email, password: password })];
                case 1:
                    _c.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _c.sent();
                    if (err_1.response.data.code === 2) {
                        if (err_1.response.status === 404) {
                            setError('email', { message: err_1.response.data.msg });
                        }
                        else if (err_1.response.status === 401) {
                            setError('password', { message: err_1.response.data.msg });
                        }
                        else {
                            console.error('An error occurred');
                        }
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleRegister = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var result, statusCode, error_1;
        var email = _b.email, password = _b.password;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Api.post("".concat(ARRIVALS_API_URL, "/auth/register"), {
                            email: email,
                            password: password
                        })];
                case 1:
                    result = _c.sent();
                    statusCode = result.data.code;
                    if (statusCode === 0) {
                        setSuccessMessage(true);
                    }
                    else if (statusCode === 2) {
                        setError('email', { message: result.data.msg });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    console.error(error_1);
                    return [2 /*return*/];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleAuthStatusChange = function () {
        setActiveView(activeView === VIEWS.REGISTER ? VIEWS.LOGIN : VIEWS.REGISTER);
    };
    var onSubmit = function (data) {
        if (activeView === VIEWS.REGISTER) {
            handleRegister(data);
        }
        else {
            handleLogin(data);
        }
    };
    return (React.createElement("div", { className: "auth-wrapper" },
        React.createElement("h2", { className: "auth-wrapper__title" }, activeView === VIEWS.REGISTER ? 'Register' : 'Login'),
        React.createElement("form", { className: "auth-wrapper__form", onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", null,
                React.createElement("input", __assign({ className: "auth-wrapper__input ".concat(errors.email ? 'auth-wrapper__input--error' : ''), placeholder: "Email", type: "email" }, register('email', {
                    required: 'Email is required',
                    validate: {
                        hasDotAfterAt: function (value) {
                            return /@.*\./.test(value) || "Email must contain a '.' after '@'";
                        }
                    }
                }))),
                errors.email ? (React.createElement("span", { className: "auth-wrapper__error" }, errors.email.message)) : null),
            React.createElement("div", null,
                React.createElement("input", __assign({ className: "auth-wrapper__input ".concat(errors.password ? 'auth-wrapper__input--error' : ''), type: "password", placeholder: "Password" }, register('password', { required: 'Password is required' }))),
                errors.password ? (React.createElement("span", { className: "auth-wrapper__error" }, errors.password.message)) : null),
            activeView === VIEWS.REGISTER && (React.createElement("h3", { className: "auth-wrapper__success-message ".concat(!successMessage && 'auth-wrapper__success-message--hidden') },
                "Successfully registered.",
                ' ',
                React.createElement("span", { className: "auth-wrapper__auth-link", onClick: handleAuthStatusChange }, "Login"),
                ' ',
                "to continue")),
            React.createElement("div", { className: "flex flex-col w-full gap-2" },
                React.createElement("button", { className: "auth-wrapper__btn auth-wrapper__btn--".concat(activeView === VIEWS.REGISTER ? 'outline' : 'fill'), type: "submit" }, activeView === VIEWS.REGISTER ? 'Register' : 'Login'))),
        React.createElement("span", null,
            activeView === VIEWS.REGISTER ? 'Already ' : "Don't",
            " have an account?",
            ' ',
            React.createElement("span", { className: "auth-wrapper__auth-link", onClick: handleAuthStatusChange }, activeView === VIEWS.REGISTER ? 'Login ' : 'Signup'))));
};
export default SignIn;
