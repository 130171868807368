// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #1d1d1d;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.header__link-wrapper {
  display: flex;
  gap: 22px;
  margin-top: auto;
  margin-bottom: auto;
}
.header__link {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.header__link:hover {
  opacity: 0.7;
}
.header__link--active {
  color: #9b9b9b;
}`, "",{"version":3,"sources":["webpack://./src/components/header/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,qBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAIE;EACE,cAAA;AAFJ","sourcesContent":[".header {\n  background-color: #1d1d1d;\n  padding: 20px;\n  display: flex;\n  justify-content: space-between;\n\n  &__link-wrapper {\n    display: flex;\n    gap: 22px;\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n\n  &__link {\n    text-decoration: none;\n    color: white;\n    font-size: 16px;\n    font-weight: 600;\n\n    &:hover {\n      opacity: 0.7;\n    }\n  }\n\n  &__link--active {\n    color: #9b9b9b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
