// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-component-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10px;
  background: #1d1d1d;
}
.add-component-container__header {
  font-size: 15px;
  margin: 5px 0 10px 0;
}
.add-component-container__select {
  text-align: left;
  width: 200px;
}
.add-component-container__select .select__control {
  background: #161616;
  height: 35px;
  color: #1faaf2;
}
.add-component-container__select .option {
  display: flex;
  justify-content: space-between;
}
.add-component-container__select .option span {
  color: #1faaf2;
}
.add-component-container input[type=text]:focus {
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/components/UI/addComponent/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,oBAAA;AACJ;AAEE;EACE,gBAAA;EACA,YAAA;AAAJ;AACI;EACE,mBAAA;EACA,YAAA;EACA,cAAA;AACN;AACI;EACE,aAAA;EACA,8BAAA;AACN;AAAM;EACE,cAAA;AAER;AAGE;EACE,gBAAA;AADJ","sourcesContent":[".add-component-container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 20px 10px;\n  background: #1d1d1d;\n\n  &__header {\n    font-size: 15px;\n    margin: 5px 0 10px 0;\n  }\n\n  &__select {\n    text-align: left;\n    width: 200px;\n    .select__control {\n      background: #161616;\n      height: 35px;\n      color: #1faaf2;\n    }\n    .option {\n      display: flex;\n      justify-content: space-between;\n      span {\n        color: #1faaf2;\n      }\n    }\n  }\n\n  input[type='text']:focus {\n    box-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
