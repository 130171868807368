import { useEffect } from 'react';
import './3dindex.js';

const AFrameRenderer = () => {
  useEffect(() => {
    if (location.pathname === '/editor/' || location.pathname === '/editor') {
      AFRAME.INSPECTOR.doInit();
      AFRAME.registerComponent('arrival-previewer', {
        init: function () {
          var sceneEl = document.querySelector('a-scene');
          const urlParams = new URLSearchParams(window.location.search);
          const object = urlParams.get('object');
          var gltfModel = document.createElement('a-asset-item');
          gltfModel.setAttribute('src', object);
          gltfModel.setAttribute('id', 'generated-model');
          gltfModel.setAttribute('position', {
            x: 0,
            y: 0,
            z: 0
          });
          gltfModel.setAttribute('scale', {
            x: 1,
            y: 1,
            z: 1
          });
          sceneEl.appendChild(gltfModel);

          var entity = document.createElement('a-entity');
          entity.setAttribute('gltf-model', '#generated-model');
          sceneEl.appendChild(entity);
        }
      });
    }
    return () => {
      delete AFRAME.components['arrival-previewer'];
      document.body.classList.remove('aframe-inspector-opened');
    };
  }, []);

  return <a-scene arrival-previewer></a-scene>;
};

export default AFrameRenderer;
