var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import Modal from '../../modals/Modal';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useSwitchChain, useSignMessage } from 'wagmi';
import { filterHelpers } from '../../scenegraph/Toolbar';
import { useCookies } from 'react-cookie';
import { BASE_URL } from '../../../../../constants/api';
import Api from '../../../../../utils/api';
import './style.scss';
var DeployButton = function (_a) {
    var handleLoading = _a.handleLoading;
    var signMessageAsync = useSignMessage({}).signMessageAsync;
    var _b = useState(false), isDeployReady = _b[0], setDeployReady = _b[1];
    var _c = useAccount(), isConnected = _c.isConnected, addresses = _c.addresses, address = _c.address;
    var switchChain = useSwitchChain().switchChain;
    var _d = useState(null), entityId = _d[0], setEntityId = _d[1];
    var cookies = useCookies(['token'])[0];
    var _e = useState(false), isModalOpen = _e[0], setIsModalOpen = _e[1];
    var web3ModalOpen = useWeb3Modal().open;
    var deployScene = function () { return __awaiter(void 0, void 0, void 0, function () {
        var scene;
        return __generator(this, function (_a) {
            handleLoading(true);
            scene = AFRAME.scenes[0].object3D;
            filterHelpers(scene, false);
            AFRAME.INSPECTOR.exporters.gltf.parse(scene, function (buffer) {
                return __awaiter(this, void 0, void 0, function () {
                    var data, response, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                filterHelpers(scene, true);
                                data = new FormData();
                                data.append('scene', new Blob([buffer]), 'scene.glb');
                                /* TODO: Replace this with user data
                                  - base should be the coordinates where the user wants to deploy to
                                  - parcels should be the other surrouning coordinates that the user owns
                                  - please note that the base coordinates should also be included in parcels or dcl will throw an error
                                */
                                data.set('parcels', JSON.stringify({
                                    scene: {
                                        parcels: [
                                            '-29,13',
                                            '-29,14',
                                            '-28,13',
                                            '-28,14',
                                            '-27,13',
                                            '-27,14'
                                        ],
                                        base: '-29,14'
                                    }
                                }));
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, 4, 5]);
                                return [4 /*yield*/, Api.post("".concat(BASE_URL, "/deploy/create"), data)];
                            case 2:
                                response = _a.sent();
                                setEntityId(response.data.data.entityId);
                                return [3 /*break*/, 5];
                            case 3:
                                error_1 = _a.sent();
                                console.error('Upload failed:', error_1);
                                throw error_1;
                            case 4:
                                handleLoading(false);
                                return [7 /*endfinally*/];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            }, function (error) {
                console.error(error);
                handleLoading(false);
            }, { binary: true });
            handleLoading(false);
            return [2 /*return*/];
        });
    }); };
    var handleDeployClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDeployReady(true);
                    if (!!isConnected) return [3 /*break*/, 2];
                    return [4 /*yield*/, web3ModalOpen()];
                case 1:
                    _a.sent();
                    switchChain({ chainId: 1 });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleSignMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var signature, result, apiError_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!entityId) return [3 /*break*/, 13];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 9, , 13]);
                    return [4 /*yield*/, signMessageAsync({
                            message: entityId,
                            account: address
                        })];
                case 2:
                    signature = _a.sent();
                    if (!(signature && typeof signature === 'string')) return [3 /*break*/, 7];
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, Api.post("".concat(BASE_URL, "/deploy/deploy"), {
                            entityId: entityId,
                            address: addresses[0],
                            signature: signature
                        })];
                case 4:
                    result = _a.sent();
                    if (result.status === 200) {
                        setIsModalOpen(true);
                        setDeployReady(false);
                    }
                    return [3 /*break*/, 6];
                case 5:
                    apiError_1 = _a.sent();
                    console.error('API request failed:', apiError_1);
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 8];
                case 7:
                    console.error('Invalid signature:', signature);
                    _a.label = 8;
                case 8: return [3 /*break*/, 13];
                case 9:
                    error_2 = _a.sent();
                    if (!(error_2.code === 4001)) return [3 /*break*/, 11];
                    return [4 /*yield*/, handleSignMessage()];
                case 10:
                    _a.sent();
                    return [3 /*break*/, 12];
                case 11:
                    console.error('Signing failed:', error_2);
                    _a.label = 12;
                case 12: return [3 /*break*/, 13];
                case 13: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (isConnected && isDeployReady) {
            deployScene();
        }
    }, [isConnected, isDeployReady]);
    useEffect(function () {
        if (entityId !== null) {
            handleSignMessage();
        }
    }, [entityId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isModalOpen, onClose: function () { return setIsModalOpen(false); }, title: "Success" },
            React.createElement("span", null, "Your scene has been deployed successfully!")),
        React.createElement("a", { className: "deploy-button", title: "Export to GLTF", onClick: handleDeployClick },
            React.createElement("span", null, "Deploy"))));
};
export default DeployButton;
