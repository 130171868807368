// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-wallet-btn {
  cursor: pointer;
  border: none;
  background-color: rgb(146, 55, 77);
  box-sizing: content-box;
  color: #fafafa;
  font-size: 22px;
  line-height: normal;
  margin-right: 10px;
  text-decoration: none;
  padding: 0px;
  height: 100%;
  padding: 3px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.connect-wallet-btn:hover {
  background-color: rgb(126, 55, 77);
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/components/UI/connectWallet/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,kCAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,6BAAA;EACA,8BAAA;AACF;AACE;EACE,kCAAA;AACJ","sourcesContent":[".connect-wallet-btn {\n  cursor: pointer;\n  border: none;\n  background-color: rgb(146, 55, 77);\n  box-sizing: content-box;\n  color: #fafafa;\n  font-size: 22px;\n  line-height: normal;\n  margin-right: 10px;\n  text-decoration: none;\n  padding: 0px;\n  height: 100%;\n  padding: 3px !important;\n  padding-left: 10px !important;\n  padding-right: 10px !important;\n\n  &:hover {\n    background-color: rgb(126, 55, 77);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
