import { useAuth } from '../../context/authProvider';
import { Navigate } from 'react-router-dom';
import React from 'react';
var PublicRoute = function (_a) {
    var children = _a.children;
    var token = useAuth().token;
    if (token) {
        return React.createElement(Navigate, { to: "/", replace: true });
    }
    return children;
};
export default PublicRoute;
