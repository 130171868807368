import React from 'react';
import PropTypes from 'prop-types';

import NumberWidget from './NumberWidget';
import { areVectorsEqual } from '../../lib/utils';
import { Slider } from '@/components/ui/slider';

export default class Vec3Widget extends React.Component {
  static propTypes = {
    componentname: PropTypes.string,
    entity: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.object.isRequired,
    sliderValue: PropTypes.number,
    setSlider: PropTypes.func,
    isSliderChecked: PropTypes.bool,
    setSliderChecked: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      x: props.value.x,
      y: props.value.y,
      z: props.value.z,
      slider: [props.sliderValue]
    };
  }

  onChange = (name, value) => {
    this.setState({ [name]: parseFloat(value.toFixed(5)) }, () => {
      if (this.props.onChange) {
        this.props.onChange(name, this.state);
      }
    });
  };

  onSliderChange = (val) => {
    const value = val;
    const numVal = value[0];

    this.props.setSlider(val);
    this.setState(() => {
      if (this.props.onChange) {
        this.props.onChange(this.props.componentname, {
          x: numVal,
          y: numVal,
          z: numVal
        });
      }
    });
  };

  checkSlider = (event) => {
    this.props.setSliderChecked(event.target.checked);
  };

  componentDidUpdate() {
    const props = this.props;
    if (!areVectorsEqual(props.value, this.state)) {
      this.setState({
        x: props.value.x,
        y: props.value.y,
        z: props.value.z,
        slider: [props.sliderValue]
      });
    }
  }

  render() {
    const isScaleNumbersDisabled =
      this.props.componentname === 'scale' && this.props.isSliderChecked;
    const widgetProps = {
      componentname: this.props.componentname,
      entity: this.props.entity,
      onChange: this.onChange
    };

    return (
      <div className="w-full">
        <div className="vec3">
          <NumberWidget
            disabled={isScaleNumbersDisabled}
            name="x"
            value={this.state.x}
            {...widgetProps}
          />
          <NumberWidget
            disabled={isScaleNumbersDisabled}
            name="y"
            value={this.state.y}
            {...widgetProps}
          />
          <NumberWidget
            disabled={isScaleNumbersDisabled}
            name="z"
            value={this.state.z}
            {...widgetProps}
          />
        </div>
        {this.props.componentname === 'scale' ? (
          <div className="vec3-slider">
            <input
              type="checkbox"
              value={this.props.isSliderChecked}
              onChange={this.checkSlider}
            />
            <Slider
              disabled={!this.props.isSliderChecked}
              onValueChange={this.onSliderChange}
              min={1}
              max={10}
              step={0.01}
              value={this.state.slider}
              className={`slider ${
                !this.props.isSliderChecked && 'slider-disabled'
              }`}
            />
            <span
              className={`number ${
                !isScaleNumbersDisabled && 'number-disabled'
              }`}
            >
              {Number(this.state.slider).toFixed(3)}
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}
