// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gltf-icon img {
  box-sizing: content-box;
  display: inline;
  height: 20px;
  left: 5px;
  padding: 0 5px;
  position: relative;
  top: 4px;
  vertical-align: baseline;
  width: 20px;
}

.gltf-icon-sidebar img {
  height: 20px;
  width: 20px;
}

.component-entity-header__title {
  display: flex;
  justify-content: space-between;
}
.component-entity-header .collapse-button {
  display: none;
}
.component-entity-header .static {
  height: 13px;
}
.component-entity-header .entityPrint {
  font-size: 15px;
  padding-left: 5px;
}
.component-entity-header .entityName {
  max-width: 160px;
  top: 0;
}
.component-entity-header .entityIcons {
  color: #fafafa;
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/components/UI/commonComponents/style.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,eAAA;EACA,YAAA;EACA,SAAA;EACA,cAAA;EACA,kBAAA;EACA,QAAA;EACA,wBAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEE;EACE,aAAA;EACA,8BAAA;AACJ;AAEE;EACE,aAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,eAAA;EACA,iBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,MAAA;AAHJ;AAME;EACE,cAAA;AAJJ","sourcesContent":[".gltf-icon img {\n  box-sizing: content-box;\n  display: inline;\n  height: 20px;\n  left: 5px;\n  padding: 0 5px;\n  position: relative;\n  top: 4px;\n  vertical-align: baseline;\n  width: 20px;\n}\n\n.gltf-icon-sidebar img {\n  height: 20px;\n  width: 20px;\n}\n.component-entity-header {\n  &__title {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .collapse-button {\n    display: none;\n  }\n\n  .static {\n    height: 13px;\n  }\n\n  .entityPrint {\n    font-size: 15px;\n    padding-left: 5px;\n  }\n\n  .entityName {\n    max-width: 160px;\n    top: 0;\n  }\n\n  .entityIcons {\n    color: #fafafa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
