// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  margin-top: 0px;
  min-height: 100px;
  background-color: #333333;
  border-radius: 10px;
}
.generate-card__content-wrapper {
  padding: 10px;
}
.generate-card__content {
  font-size: 20px;
}
.generate-card__loading-bar {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: #ff6f61;
  border-radius: 0 0 5px 5px;
  border-radius: 10px;
  overflow: hidden;
}
.generate-card__loading-bar div {
  position: absolute;
  display: block;
  height: 100%;
  background-color: #ff4081;
  animation: loading 30s linear forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/components/UI/generateCard/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,0BAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAEI;EACE,kBAAA;EACA,cAAA;EACA,YAAA;EACA,yBAAA;EACA,sCAAA;AAAN;;AAKA;EACE;IACE,SAAA;EAFF;EAIA;IACE,WAAA;EAFF;AACF","sourcesContent":[".generate-card {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin: 10px;\n  margin-top: 0px;\n  min-height: 100px;\n  background-color: #333333;\n  border-radius: 10px;\n\n  &__content-wrapper {\n    padding: 10px;\n  }\n\n  &__content {\n    font-size: 20px;\n  }\n\n  &__loading-bar {\n    position: relative;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 15px;\n    background-color: #ff6f61;\n    border-radius: 0 0 5px 5px;\n    border-radius: 10px;\n    overflow: hidden;\n    div {\n      position: absolute;\n      display: block;\n      height: 100%;\n      background-color: #ff4081;\n      animation: loading 30s linear forwards;\n    }\n  }\n}\n\n@keyframes loading {\n  0% {\n    width: 0%;\n  }\n  100% {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
