// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.collapsible-header .entityPrint {
  color: #fff;
}
.collapsible-header__title span {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
  font-weight: 600;
  vertical-align: bottom !important;
}
.collapsible-header__collapsible-content {
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/sceneEditor/components/UI/component/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;AACF;AACE;EACE,WAAA;AACJ;AAGI;EACE,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,iCAAA;AADN;AAIE;EACE,cAAA;AAFJ","sourcesContent":[".collapsible-header {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n\n  .entityPrint {\n    color: #fff;\n  }\n\n  &__title {\n    span {\n      max-width: 200px;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      text-transform: uppercase;\n      white-space: nowrap;\n      color: #fff;\n      font-weight: 600;\n      vertical-align: bottom !important;\n    }\n  }\n  &__collapsible-content {\n    padding: 5px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
