import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GenerateCard from '../UI/generateCard';
import Api from '../../../../utils/api';
import '../../style/generate.scss';
import { ARRIVALS_API_URL } from '../../../../config';

const GenerateButton = ({ objectName, loadedEntity }) => {
  const { entityName, isLoaded } = loadedEntity;

  const [items, setItems] = useState([]);
  const [prompt, setPrompt] = useState('');

  const handleClick = async () => {
    const now = Date.now();
    const lastItem = items[items.length - 1];
    const lastAnimationEnd = lastItem
      ? lastItem.addedAt + lastItem.delay * 1000 + 30000
      : 0;
    const delay = Math.max(0, (lastAnimationEnd - now) / 1000);
    const newItem = { id: now, value: prompt, delay: delay, addedAt: now };
    setItems((prevItems) => [...prevItems, newItem]);
    const clientId = localStorage.getItem('clientId');

    try {
      await Api.post(`${ARRIVALS_API_URL}/generate/model`, {
        prompt: prompt,
        clientId: clientId
      });
    } catch (err) {
      console.error(err);
    }
    setPrompt('');
  };

  useEffect(() => {
    if (objectName === entityName && isLoaded) {
      setTimeout(() => {
        setItems((prevItems) => {
          const index = prevItems.findIndex(
            (item) => item.value === objectName
          );
          if (index !== -1) {
            return [
              ...prevItems.slice(0, index),
              ...prevItems.slice(index + 1)
            ];
          }
          return prevItems;
        });
      }, 1000);
    }
  }, [objectName, entityName, isLoaded, items]);

  return (
    <div className="generate">
      <div className="generate__list">
        {items.map((item, index) => (
          <GenerateCard key={index} value={item.value} delay={item.delay} />
        ))}
      </div>
      <div className="generate__prompt-wrapper">
        <textarea
          type="text"
          rows="4"
          wrap="hard"
          className="generate__textarea"
          placeholder="Write prompt"
          onChange={(e) => setPrompt(e.target.value)}
          value={prompt}
        />
        <button
          className={`generate__button ${
            prompt === ''
              ? 'generate__button--disabled'
              : 'generate__button--enabled'
          }`}
          disabled={prompt === ''}
          onClick={handleClick}
        >
          Generate
        </button>
      </div>
    </div>
  );
};

GenerateButton.propTypes = {
  objectName: PropTypes.string,
  loadedEntity: PropTypes.object
};

export default GenerateButton;
