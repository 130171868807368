import React from 'react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { mainnet } from 'wagmi/chains';
import { WagmiProvider } from 'wagmi';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import SignIn from './pages/login';
import Header from './components/header';
import UserProfile from './pages/userProfile';
import SceneEditor from './pages/sceneEditor';
import { AuthProvider } from './context/authProvider';
import ProtectedRoute from './routes/protectedRoute';
import PublicRoute from './routes/publicRoute';
import { ROUTES } from './constants/routes';
import './app.scss';
var queryClient = new QueryClient();
var projectId = process.env.WEB3_PROJECT_ID;
var metadata = {
    name: 'arrival',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
    description: 'A Web3Modal example project'
};
var config = defaultWagmiConfig({
    chains: [mainnet],
    projectId: projectId,
    metadata: metadata
});
createWeb3Modal({
    wagmiConfig: config,
    projectId: projectId,
    enableAnalytics: true,
    enableOnramp: true
});
var router = createBrowserRouter([
    {
        path: '/',
        element: (React.createElement(ProtectedRoute, null,
            React.createElement("header", { className: "app-header" },
                React.createElement(Header, null)),
            React.createElement("main", { className: "main" },
                React.createElement(Outlet, null)))),
        children: [
            {
                path: ROUTES.root,
                element: React.createElement(UserProfile, null)
            },
            {
                path: ROUTES.userProfile,
                element: React.createElement(UserProfile, null)
            },
            {
                path: ROUTES.editor,
                element: React.createElement(SceneEditor, null)
            }
        ]
    },
    {
        path: ROUTES.login,
        element: (React.createElement(PublicRoute, null,
            React.createElement(Outlet, null))),
        children: [
            {
                path: ROUTES.login,
                element: React.createElement(SignIn, null)
            }
        ]
    }
]);
export default function App() {
    return (React.createElement(WagmiProvider, { config: config },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(AuthProvider, null,
                React.createElement(RouterProvider, { router: router })))));
}
