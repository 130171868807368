import axios from 'axios';
import Cookies from 'js-cookie';
import { ARRIVALS_API_URL } from '../config';

class Api {
  constructor() {
    this.api = axios.create();

    this.api.interceptors.request.use(
      (config) => {
        const token = Cookies.get('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  async login(data) {
    const response = await this.api.post(
      `${ARRIVALS_API_URL}/auth/login`,
      data
    );
    Cookies.set('token', response.data.data.token);
    return response.data;
  }

  async post(endpoint, data) {
    const response = await this.api.post(endpoint, data);
    return response;
  }
}

export default new Api();
